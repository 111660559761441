import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "gatsby";

import favicon from "../images/favicon.ico";
import seoImg from "../images/metaimg.png";

import styles from "./layout.module.css";

export default function Layout({ children }) {
  return (
    <div className={styles.container}>
      <Helmet>
          <meta charSet="utf-8" />
          <link rel="shortcut icon" href={favicon} />

          {/* Primary Meta Tags */}
          <title>Mary Rose Tan — Digital Product Designer & Web Developer</title>
          <meta name="title" content="Mary Rose Tan — Digital Product Designer & Web Developer" />
          <meta name="description" content="Digital product designer and Web developer based in Cebu, Philippines." />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://maryrosetan.com/" />
          <meta property="og:title" content="Mary Rose Tan — Digital Product Designer & Web Developer" />
          <meta property="og:description" content="Digital product designer and Web developer based in Cebu, Philippines." />
          <meta property="og:image" content={seoImg} />

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image"  />
          <meta property="twitter:url" content="https://maryrosetan.com/" />
          <meta property="twitter:title" content="Mary Rose Tan — Digital Product Designer & Web Developer" />
          <meta property="twitter:description" content="Digital product designer and Web developer based in Cebu, Philippines." />
          <meta property="twitter:image" content={seoImg} />
      </Helmet>

      <header
        className={styles.header}
      >
        <Link 
          to="/"
          className={styles.headerName}
        >
          <h3>Mary Rose Tan</h3>
        </Link>
      </header>
      {children}
      <div>
        <div className={styles.comingSoon}>
          <p>Featured works coming soon...</p>
        </div>
        
        <div className={styles.socialMedia}>

          <a href="mailto:mrosetan@gmail.com">mrosetan@gmail.com</a>

          <a href="https://dribbble.com/maryrosetan" target="_blank" rel="noreferrer">Dribbble</a>

          <a href="https://www.linkedin.com/in/maryrosetan/" target="_blank" rel="noreferrer">LinkedIn</a>
        </div>
      </div>
      
    </div>
  )
}