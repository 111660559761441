import React from "react"
import Layout from "../components/layout"

import rose1 from "../images/rose-1.png"

import styles from "./index.module.css"

export default function Home() {
  return (
    <Layout>
      <div className={styles.heroContainer}>
        <div>
          <h1>Hi! I’m Rose. Digital Product Designer and Web Developer.</h1>
          <p className={styles.subheader}>Currently working at 
            <a href="https://www.symph.co/" target="_blank" rel="noreferrer">{' '}Symph</a>
          </p>
        </div>
        <div className={styles.heroImg}>
          <img src={rose1} alt="Rose Tan" />
        </div>
      </div>
      
    </Layout>
  )
}
